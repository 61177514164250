/*
 ************************************************************************
 * DELL PROPRIETARY INFORMATION
 *
 * This software contains the intellectual property of Dell Inc. Use of this software and the intellectual property
 * contained therein is expressly limited to the terms and conditions of the License Agreement under which it is
 * provided by or on behalf of Dell Inc. or its subsidiaries.
 *
 * Copyright 2024 Dell Inc. or its subsidiaries. All Rights Reserved.
 *
 * DELL INC. MAKES NO REPRESENTATIONS OR WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE, EITHER
 * EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT.  DELL SHALL NOT BE LIABLE FOR ANY DAMAGES
 * SUFFERED BY LICENSEE AS A RESULT OF USING, MODIFYING OR DISTRIBUTING THIS SOFTWARE OR ITS
 * DERIVATIVES.
 ************************************************************************
 */

export const navRoutes = [
  {
    id: 101,
    link: "/dashboard/home",
    title: "Dashboard",
    dds_icon_class: "dds__icon--home",
    external: false,
  },
  {
    id: 102,
    link: "/dashboard/devices",
    title: "Devices",
    dds_icon_class: "dds__icon--device-monitor",
    external: false,
  },
  {
    id: 103,
    link: "/dashboard/apps",
    title: "Apps",
    dds_icon_class: "dds__icon--package-closed",
    external: false,
  },
  {
    id: 104,
    link: "https://tdm.dell.com/portal",
    title: "TechDirect",
    dds_icon_class: "dds__icon--windows-support",
    external: true,
  },
  // {
  //   id: 105,
  //   link: 'https://admin.microsoft.com/Adminportal/Home?invType=OEM&partnerId=4f087693-60fa-4082-be28-2fc6d116136b#/partners/invitation',
  //   title: 'Autopilot',
  //   dds_icon_class: 'dds__icon--management-software',
  //   external: true
  // }
];
