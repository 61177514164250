/*
 ************************************************************************
 * DELL PROPRIETARY INFORMATION
 *
 * This software contains the intellectual property of Dell Inc. Use of this software and the intellectual property
 * contained therein is expressly limited to the terms and conditions of the License Agreement under which it is
 * provided by or on behalf of Dell Inc. or its subsidiaries.
 *
 * Copyright 2024 Dell Inc. or its subsidiaries. All Rights Reserved.
 *
 * DELL INC. MAKES NO REPRESENTATIONS OR WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE, EITHER
 * EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT.  DELL SHALL NOT BE LIABLE FOR ANY DAMAGES
 * SUFFERED BY LICENSEE AS A RESULT OF USING, MODIFYING OR DISTRIBUTING THIS SOFTWARE OR ITS
 * DERIVATIVES.
 ************************************************************************
 */

import { useNavigate } from "react-router-dom";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { React, useEffect, useState } from "react";
import { loginRequest } from "common/auth/authConfig";
import { Popover } from "@dds/components";
import "./index.scss";
import MsIcon from "../../../images/MicrosoftIcon.png";
import DellBlue from "../../../images/logo-blue.svg";
import DellWhite from "../../../images/logo-white.svg";

/**
 * Renders the header component.
 *
 * @return {JSX.Element} The rendered header component.
 */
const Header = () => {
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();
  const { instance } = useMsal();

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/dashboard");
    }
  }, [navigate, isAuthenticated]);

  useEffect(() => {
    const popover = document.getElementById("popover-838264705");
    new Popover(popover, {
      trigger: "#popover-838264705-trigger",
      placement: "right",
      closeOnScroll: true,
    });
  }, []);

  /**
   * Handles the login process by redirecting to the login page.
   *
   * @return {Promise<void>} A promise that resolves when the login process is complete.
   */
  const handleLogin = () => {
    instance.loginRedirect(loginRequest).catch((e) => {
      if (e === null || e === undefined) {
        console.error("Error during login");
      } else {
        console.error("Error during login:", e.message);
      }
    });
  };

  const [scrollPercentage, setScrollPercentage] = useState(0);

  /**
   * Calculates the scroll percentage based on the current scroll position and the total height of the document.
   *
   * @return {void}
   */
  const handleScroll = () => {
    const windowHeight = window.innerHeight;
    const fullHeight = document.documentElement.scrollHeight;
    const currentPosition = window.scrollY;

    const scrollPercentage =
      (currentPosition / (fullHeight - windowHeight)) * 100;

    setScrollPercentage(scrollPercentage);
  };

  // If the window's inner width is greater than or equal to 2560 pixels (indicating a 4K resolution or higher), set the target height to 52.
  // If the window's inner width is between 768 and 1024 pixels (indicating a tablet resolution), set the target height to 26.
  // If the window's inner width is between 1024 and 1440 pixels (indicating a laptop resolution), set the target height to 32.
  // If the window's inner width is between 1440 and 1920 pixels (indicating a large laptop resolution), set the target height to 33.5.
  // For all other resolutions, set the target height to 38.
  let targetHeight = 0;
  const TabletWidth = 768;
  const LaptopWidth = 1024;
  const LargeLaptopWidth = 1440;
  const DesktopWidth = 1920;
  const _4K_Width = 2500;
  if (window.innerWidth >= _4K_Width) {
    targetHeight = 52;
  } else if (
    window.innerWidth >= TabletWidth &&
    window.innerWidth < LaptopWidth
  ) {
    targetHeight = 26;
  } else if (
    window.innerWidth >= LaptopWidth &&
    window.innerWidth < LargeLaptopWidth
  ) {
    targetHeight = 32;
  } else if (
    window.innerWidth >= LargeLaptopWidth &&
    window.innerWidth < DesktopWidth
  ) {
    targetHeight = 33.5;
  } else {
    targetHeight = 38;
  }

  const [isLess, setIsLess] = useState(scrollPercentage < targetHeight);

  useEffect(() => {
    setIsLess(scrollPercentage > targetHeight);
  }, [scrollPercentage, targetHeight]);


  useEffect(() => {
    if (isLess) {
    let element = document.getElementById("popover-838264705");
    new Popover(element, {
      trigger: "#popover-838264705-trigger",
      placement: "right",
      closeOnScroll: true,
    });
    }
  }, [isLess]);


  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollPercentage]);

  return (
    <>
      <div className="main__container">
        {scrollPercentage < targetHeight ? (
          <div className="header-white dds__d-flex">
             <img src={DellWhite} alt="" className="dell-logo"/>
             <h5 className="dds__h5 header-title-black">Management Portal</h5>
          </div>
        ) : (
          <div className="header-blue">
            <div className="dds__d-flex">
            <span><img src={DellBlue} alt="" className="dell-logo"/>
            </span>
             <h5 className="dds__h5 header-title-white">Management Portal</h5>
              </div>
            <div className="fade-in dds__d-flex dds__justify-content-center dds__flex-sm-row button-class">
              <button
                className="dds__button dds__button--primary dds__button--editorial-light dds__button--sm dds__mb-3 dds__pt-2 dds__pb-2 dds__pr-3 dds__pl-3 dds__mr-4"
                type="button"
                onClick={handleLogin}
              >
                <p className="dds__body-2 connect_btn dds__mt-2">Connect now{" "}</p>
                <img src={MsIcon} alt="" className="dds__ml-2 dds__icon dds__icon--view-grid-alt" />
              </button>
              <a id="popover-838264705-trigger" className="popover-alignment-base" aria-label="Tooltip Icon" role="button">
              <span className="dds__icon dds__icon--alert-info-cir info-icon"></span>
              </a>
              <div className="custom__popover-connect-now-info">
              <div
                id="popover-838264705"
                data-dds="popover"
                className="dds__popover"
                role="dialog"
              >
                <div className="dds__popover__content">
                  <div className="dds__popover__header">
                    <h6
                      id="popover-title-335747891"
                      className="dds__popover__headline"
                    ></h6>
                  </div>
                  <div className="dds__popover__body">
                    Select <b>Connect now</b> and you’ll see a Microsoft Dialog Box that lists permissions
                    that would be managed through Microsoft Graph API. Once
                    you’ve reviewed the list, select “I consent,” and we’ll walk
                    you through the rest.
                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Header;
