/*
 ************************************************************************
 * DELL PROPRIETARY INFORMATION
 *
 * This software contains the intellectual property of Dell Inc. Use of this software and the intellectual property
 * contained therein is expressly limited to the terms and conditions of the License Agreement under which it is
 * provided by or on behalf of Dell Inc. or its subsidiaries.
 *
 * Copyright 2024 Dell Inc. or its subsidiaries. All Rights Reserved.
 *
 * DELL INC. MAKES NO REPRESENTATIONS OR WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE, EITHER
 * EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT.  DELL SHALL NOT BE LIABLE FOR ANY DAMAGES
 * SUFFERED BY LICENSEE AS A RESULT OF USING, MODIFYING OR DISTRIBUTING THIS SOFTWARE OR ITS
 * DERIVATIVES.
 ************************************************************************
 */

import { React } from "react";
import "./index.scss";

/**
 * Renders the footer component.
 *
 * @return {JSX.Element} The rendered footer component.
 */
const Footer = () => {
  return (
    <>
      <div className="footer">
        <div className="dds__container footer-container">
          <div className="dds__row">
            <div className="dds__col-2 dds__col--sm-3 dds__col--md-8 dds__align-left">
              <div className="sample-box">
                <h3 className="dds__h3">What is Dell Management Portal for Microsoft Intune?</h3>
                <p className="dds__body-2 footer-content">
                  Amplify control over your Dell devices. Access BIOS passwords,
                  view the latest versions of our IT apps, and easily publish
                  them to stay compliant, saving you time and hassle.
                </p>
              </div>
              
              <div className="dds__row dds__body-2" id="langPicker">              
                  <span className="dds__icon dds__icon--globe-simple footer-icon"></span>
                  <p className="dds__body-2 dds__mb-0">English (US)</p>
              </div>
            </div>
            <div className="dds__col-2 dds__col--sm-auto dds__col--md-4 ">
              <div className="links dds__body-2">
                <a href="https://www.dell.com" target="_blank" rel="noreferrer">
                  <p>About Dell</p>
                </a>
                <a
                  href="https://www.dell.com/support"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p>Dell Technologies Support</p>
                </a>
                <a
                  href="https://www.dell.com/learn/us/en/uscorp1/policies-privacy"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p>Services Privacy Statement</p>
                </a>
                <a
                  href="https://www.dell.com/learn/us/en/uscorp1/site-terms-of-use-copyright"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p>© 2024 Dell Inc.</p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
